var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _vm.implicit
        ? _c(
            "div",
            [
              _c(
                "a-tabs",
                {
                  attrs: { "default-active-key": _vm.keys },
                  on: { change: _vm.callback },
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: 1, attrs: { tab: "本人调班" } },
                    [
                      _vm.keys === 1
                        ? _c("MyChange", { on: { bulkShift: _vm.bulkShift } })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: 2, attrs: { tab: "员工换班" } },
                    [
                      _vm.keys === 2
                        ? _c("Employee", { on: { bulkShift: _vm.bulkShift } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("ExcelImport", {
            attrs: {
              title: _vm.text,
              "import-template":
                _vm.key === 1 ? "change_shift" : "exchange_shift",
            },
            on: { toggleValue: _vm.toggleValue },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }