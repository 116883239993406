var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mychange" },
    [
      _c(
        "div",
        { staticClass: "change-toobar" },
        [
          _c(
            "div",
            { staticClass: "toobar-left" },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "114px", "margin-right": "12px" },
                  attrs: { title: _vm.attendance },
                  on: { change: _vm.attendanceChange },
                  model: {
                    value: _vm.attendance,
                    callback: function ($$v) {
                      _vm.attendance = $$v
                    },
                    expression: "attendance",
                  },
                },
                _vm._l(_vm.attendances, function (item, index) {
                  return _c(
                    "a-select-option",
                    {
                      key: "selectText" + index,
                      staticClass: "selectText",
                      attrs: {
                        title: item.label,
                        label: item.label,
                        value: item.value,
                      },
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "selectbutton" },
                [
                  _c(
                    "a-dropdown",
                    {
                      attrs: { type: "primary" },
                      scopedSlots: _vm._u([
                        {
                          key: "overlay",
                          fn: function () {
                            return [
                              _c(
                                "a-menu",
                                _vm._l(
                                  _vm.functionList,
                                  function (item, index) {
                                    return _c(
                                      "a-menu-item",
                                      {
                                        key: index,
                                        on: {
                                          click: function ($event) {
                                            return _vm.add(item)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "a-button",
                            { attrs: { prefix: "add-one", suffix: "left" } },
                            [_vm._v("调班")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  attrs: {
                    prefix: "reduce-one",
                    disabled: _vm.selectedRowKeys.length <= 0,
                  },
                  on: { click: _vm.isDelete },
                },
                [_c("span", [_vm._v("批量删除")])]
              ),
            ],
            1
          ),
          _c("appForm", {
            attrs: {
              "to-list": _vm.toList,
              "screen-roster-list": _vm.screenRosterList,
            },
            on: {
              getSearchCallback: _vm.getSearchCallback,
              screenRosterParams: _vm.screenRosterParams,
              exportFileList: _vm.exportFileList,
            },
          }),
        ],
        1
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "changeShiftApplyId",
          "table-ref": ".mychange",
          "tabs-height": 36,
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            columnWidth: 30,
            onChange: _vm.onSelectChange,
            getCheckboxProps: function (record) {
              return {
                props: {
                  disabled: record.operateType !== 2,
                },
              }
            },
          },
          "selection-mode": _vm.selectionMode,
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "name",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("CbTableAvater", {
                  attrs: {
                    "table-name": scope.record.staffName,
                    "on-job-id": scope.record.onJobId,
                    "staff-id": scope.record.staffId,
                    title: scope.record.staffName,
                  },
                }),
              ]
            },
          },
          {
            key: "changLogShiftNote",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("div", {
                  attrs: { title: scope.record.changLogShiftNote },
                  domProps: {
                    innerHTML: _vm._s(scope.record.changLogShiftNote),
                  },
                }),
              ]
            },
          },
          {
            key: "operateType",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "div",
                  {
                    attrs: {
                      title: _vm.getCodeContent(
                        scope.record.operateType,
                        _vm.operate_type
                      ),
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.getCodeContent(
                          scope.record.operateType,
                          _vm.operate_type
                        )
                      )
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: scope.record.operateType === 2,
                      type: "link",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.approval(scope.record)
                      },
                    },
                  },
                  [_vm._v(" 审批详情 ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("CbApproveSteps", {
        attrs: {
          "flow-id": _vm.flowId,
          "apply-status": _vm.applyStatus,
          "apply-status-list": _vm.applyStatusList,
        },
        model: {
          value: _vm.stepsVisible,
          callback: function ($$v) {
            _vm.stepsVisible = $$v
          },
          expression: "stepsVisible",
        },
      }),
      _c(
        "Modal",
        {
          attrs: {
            closable: "",
            "overflow-auto": "",
            "mask-closable": false,
            visible: _vm.addRecord,
            title: "添加调班记录",
            size: "normal",
          },
          on: { cancel: _vm.onClose, ok: _vm.onSubmin },
        },
        [
          _c("k-form-build", {
            ref: "KFB",
            attrs: { value: _vm.theSingle, "text-display": true },
            on: { change: _vm.change },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }